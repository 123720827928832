import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';  // Import the Firebase auth instance
import { signOut, onAuthStateChanged } from 'firebase/auth';  // Import signOut and state change functions

const AppbarHome = () => {
  const [user, setUser] = useState(null);  // Track the logged-in user
  const navigate = useNavigate();

  // Listen to the authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);  // Set the user state based on login status
    });
    
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');  // Redirect to home page after logging out
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#021526' }} >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            ManageME
          </Link>
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <Button color="inherit" component={Link} to="/dashboard">
          Dashboard
        </Button>
        
        {/* Conditionally render Login or Logout button based on user state */}
        {user ? (
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/signup">
              Sign Up
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppbarHome;
