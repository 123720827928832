import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';  
import { auth } from '../firebase';  
import AppbarHome from './AppbarHome';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '../assets/google_logo1.png'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();  // Initialize Google Auth Provider

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in:', userCredential.user);
      alert('Logged in successfully!');
      navigate('/dashboard');  // Redirect to dashboard
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Error logging in: ' + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);  // Trigger Google Login
      const user = result.user;
      console.log('Google user logged in:', user);
      alert('Logged in with Google successfully!');
      navigate('/dashboard');  // Redirect to dashboard
    } catch (error) {
      console.error('Error with Google login:', error);
      alert('Google login error: ' + error.message);
    }
  };

  return (
    <div>
      <AppbarHome />
      <Container maxWidth="sm" sx={{ mt: 10 }}>
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Login
          </Typography>
          <TextField
            label="Email"
            type="email"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ backgroundColor: '#021526' }}>
            Login
          </Button>
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="body1">OR</Typography>
          <Button 
            variant="outlined" 
            color="primary" 
            fullWidth 
            onClick={handleGoogleLogin}
            sx={{ mt: 2 }}
          >
            <img src={GoogleIcon} alt="Google logo" style={{ width: 20, height: 20, marginRight: 8 }} />
            Login with Google
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Login;