import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';
import BarChartIcon from '@mui/icons-material/BarChart';

const drawerWidth = 240; // Width when open
const collapsedWidth = 40; // Width when collapsed

const Sidebar = ({ open, toggleSidebar, setCurrentComponent }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: open ? drawerWidth : collapsedWidth, // Use dynamic width
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : collapsedWidth, // Match the drawer width
          boxSizing: 'border-box',
          transition: 'width 0.3s ease',
          marginTop: '64px' // Ensure the sidebar starts below the Appbar
        },
      }}
    >
      <Toolbar /> {/* This can be kept to ensure spacing */}
      <List>
        <ListItem button onClick={() => setCurrentComponent('All Sheets')}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="All Sheets" />
        </ListItem>
        <ListItem button onClick={() => setCurrentComponent('Global Search')}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Global Search" />
        </ListItem>
        <ListItem button onClick={() => setCurrentComponent('Visualization')}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Visualization" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
