import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Slider,
  TextField,
  Button,
} from '@mui/material';
import {
  LineChart,
  BarChart,
  PieChart,
  AreaChart,
  ScatterChart,
  RadialBarChart,
  RadarChart,
  Treemap,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Bar,
  Pie,
  Area,
  Scatter,
  RadialBar,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Cell,
  Label,
} from 'recharts'; // Import all necessary components from Recharts
import html2canvas from 'html2canvas';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000', '#800080']; // Define color array

const chartComponents = {
  LineChart,
  BarChart,
  PieChart,
  AreaChart,
  ScatterChart,
  RadialBarChart,
  RadarChart,
  Treemap,
};

const VisualizationTab = ({ selectedFile, tableData }) => {
  const [selectedChart, setSelectedChart] = useState('');
  const [chartParams, setChartParams] = useState({});
  const [chartSize, setChartSize] = useState(500); // State to handle chart size
  const [lineColor, setLineColor] = useState('#8884d8');  // State for LineChart color
  const [barColor, setBarColor] = useState('#8884d8');  // State for BarChart color
  const [areaColor, setAreaColor] = useState('#8884d8');  // State for AreaChart color
  const [scatterColor, setScatterColor] = useState('#8884d8');  // State for ScatterChart color
  const [radialbarColor, setRadialbarColor] = useState('#8884d8');  // State for RadialBarChart color
  const [radarColor, setRadarColor] = useState('#8884d8');  // State for RadarChart color
  const [treeColor, setTreeColor] = useState('#8884d8');  // State for TreeChart color
  const chartRef = useRef(null);

  // Handle chart selection change
  const handleChartChange = (event) => {
    setSelectedChart(event.target.value);
    setChartParams({}); // Reset parameters when chart changes
  };

  // Get headers from tableData for parameter selection
  const headers = tableData.length > 0 ? Object.keys(tableData[0]) : [];

  const validateData = () => {
    if (selectedChart === 'PieChart' && !chartParams.pieDataKey) return false;
    return true;
  };

  // Handle parameter change
  const handleParamChange = (param, value) => {
    setChartParams((prev) => ({ ...prev, [param]: value }));
  };

  // Handle chart size change
  const handleSizeChange = (event, newValue) => {
    setChartSize(newValue);
  };

  // Handle color change for LineChart
  const handleColorChange = (event) => {
    setLineColor(event.target.value);
    setBarColor(event.target.value);
    setAreaColor(event.target.value);
    setScatterColor(event.target.value);
    setRadialbarColor(event.target.value);
    setRadarColor(event.target.value);
    setTreeColor(event.target.value);
  };

  // Function to handle downloading the chart as a PNG
  const downloadChartAsPNG = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
      });
    }
  };

  // Render the selected chart component with parameters
  const renderChart = () => {
    if (!validateData()) return <Typography>Please configure the chart properly.</Typography>;
    const data = tableData; // Assuming tableData is in the right format for the chart

    if (selectedChart === 'PieChart') {
      return (
        <div ref={chartRef}>
        <PieChart width={chartSize} height={chartSize}>
          <Tooltip />
          <Pie
            data={data}
            dataKey={chartParams.pieDataKey}
            cx={chartSize / 2}
            cy={chartSize / 2}
            outerRadius={chartSize / 4}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        </div>
      );
    }

    if (selectedChart === 'RadialBarChart') {
        return (
          <div ref={chartRef}>
            <RadialBarChart width={chartSize} height={chartSize} data={data} innerRadius="10%" outerRadius="80%" barSize={10}>
            <RadialBar minAngle={15} label={{ position: 'insideStart', fill: {radialbarColor} }} background clockWise={true} dataKey={chartParams.radialBarDataKey} />
            <Tooltip />
          </RadialBarChart>
          </div>
        );
    }

    if (selectedChart === 'RadarChart') {
        return (
          <div ref={chartRef}>
            <RadarChart outerRadius={chartSize / 2} width={chartSize} height={chartSize} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey={chartParams.xAxis} />
            <PolarRadiusAxis />
            <Radar name="Radar" dataKey={chartParams.radarDataKey} stroke={radarColor} fill={radarColor} fillOpacity={0.6} />
            <Tooltip />
            </RadarChart>
            </div>
        );
    }

    if (selectedChart === 'Treemap') {
        return (
          <div ref={chartRef}>
            <Treemap width={chartSize} height={chartSize} data={data} dataKey={chartParams.treemapDataKey} stroke={treeColor} fill={treeColor} />
            </div>
        );
    }

    const ChartComponent = chartComponents[selectedChart];
    if (ChartComponent) {
      return (
        <div ref={chartRef}>
        <ChartComponent data={data} width={chartSize} height={chartSize/2}>
          <XAxis
            dataKey={chartParams.xAxis}
            label={<Label value={chartParams.xAxis} offset={-5} position="insideBottom" />}
          />
          <YAxis label={<Label value={chartParams.yAxis} angle={-90} position="insideLeft" />} />
          <Tooltip />
          {selectedChart === 'LineChart' && (
            <Line type="monotone" dataKey={chartParams.lineDataKey} stroke={lineColor} />
          )}
          {selectedChart === 'BarChart' && (
            <Bar dataKey={chartParams.barDataKey} fill={barColor} />
          )}
          {selectedChart === 'AreaChart' && (
            <Area type="monotone" dataKey={chartParams.areaDataKey} stroke={areaColor} fill={areaColor} />
          )}
          {selectedChart === 'ScatterChart' && (
            <Scatter dataKey={chartParams.scatterDataKey} fill={scatterColor} />
          )}
        </ChartComponent>
        </div>
      );
    }

    return null;
  };

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      marginBottom: 2,
      flexDirection: { xs: 'column', sm: 'row' }, // 'column' on mobile, 'row' on larger screens
    }}>
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Visualization of {selectedFile.label}
      </Typography>

      <FormControl fullWidth>
        <InputLabel id="chart-select-label">Select Chart</InputLabel>
        <Select
          labelId="chart-select-label"
          value={selectedChart}
          onChange={handleChartChange}
          displayEmpty
        >
          <MenuItem value="">
            <em></em>
          </MenuItem>
          {Object.keys(chartComponents).map((chart) => (
            <MenuItem key={chart} value={chart}>
              {chart}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Render parameter inputs based on the selected chart */}
      {selectedChart && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Chart Parameters:</Typography>

          {/* LineChart Parameters */}
          {selectedChart === 'LineChart' && (
            <>
            <Box sx={{ display: 'flex', gap: 3, marginBottom: 2 }}>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="xAxis-select-label">X-Axis</InputLabel>
                <Select
                  labelId="xAxis-select-label"
                  onChange={(e) => handleParamChange('xAxis', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="lineDataKey-select-label">Data Key</InputLabel>
                <Select
                  labelId="lineDataKey-select-label"
                  onChange={(e) => handleParamChange('lineDataKey', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
              {/* Color Picker for LineChart */}
              <TextField
                label="Line Color"
                type="color"
                value={lineColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
              

            </>
          )}

          {/* BarChart Parameters */}
          {selectedChart === 'BarChart' && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="xAxis-select-label-bar">X-Axis</InputLabel>
                <Select
                  labelId="xAxis-select-label-bar"
                  onChange={(e) => handleParamChange('xAxis', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="barDataKey-select-label">Data Key</InputLabel>
                <Select
                  labelId="barDataKey-select-label"
                  onChange={(e) => handleParamChange('barDataKey', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Color Picker for BarChart */}
              <TextField
                label="Bar Color"
                type="color"
                value={barColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
            </>
          )}

          {/* PieChart Parameters */}
          {selectedChart === 'PieChart' && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="pieDataKey-select-label">Data Key</InputLabel>
                <Select
                  labelId="pieDataKey-select-label"
                  onChange={(e) => handleParamChange('pieDataKey', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {/* AreaChart Parameters */}
          {selectedChart === 'AreaChart' && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="xAxis-select-label-area">X-Axis</InputLabel>
                <Select
                  labelId="xAxis-select-label-area"
                  onChange={(e) => handleParamChange('xAxis', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="areaDataKey-select-label">Data Key</InputLabel>
                <Select
                  labelId="areaDataKey-select-label"
                  onChange={(e) => handleParamChange('areaDataKey', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Color Picker for AreaChart */}
              <TextField
                label="Area Color"
                type="color"
                value={areaColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
            </>
          )}

          {/* ScatterChart Parameters */}
          {selectedChart === 'ScatterChart' && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="xAxis-select-label-scatter">X-Axis</InputLabel>
                <Select
                  labelId="xAxis-select-label-scatter"
                  onChange={(e) => handleParamChange('xAxis', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="scatterDataKey-select-label">Data Key</InputLabel>
                <Select
                  labelId="scatterDataKey-select-label"
                  onChange={(e) => handleParamChange('scatterDataKey', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Color Picker for ScatterChart */}
              <TextField
                label="Scatter Chart Color"
                type="color"
                value={scatterColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
            </>
          )}

          {/* RadarChart Parameters */}
          {selectedChart === 'RadarChart' && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="xAxis-select-label-radar">X-Axis</InputLabel>
                <Select
                  labelId="xAxis-select-label-radar"
                  onChange={(e) => handleParamChange('xAxis', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="radarDataKey-select-label">Data Key</InputLabel>
                <Select
                  labelId="radarDataKey-select-label"
                  onChange={(e) => handleParamChange('radarDataKey', e.target.value)}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Color Picker for RadraChart */}
              <TextField
                label="Radar Color"
                type="color"
                value={radarColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
            </>
          )}

          {selectedChart === 'RadialBarChart' && (
            <>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="radialBarDataKey-select-label">Data Key</InputLabel>
              <Select
                labelId="radialBarDataKey-select-label"
                onChange={(e) => handleParamChange('radialBarDataKey', e.target.value)}
              >
                {headers.map((header) => (
                  <MenuItem key={header} value={header}>
                    {header}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Color Picker for RadialBarChart */}
            <TextField
                label="Radial Bar Color"
                type="color"
                value={radialbarColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
            </>
          )}

          {selectedChart === 'Treemap' && (
            <>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="treemapDataKey-select-label">Data Key</InputLabel>
              <Select
                labelId="treemapDataKey-select-label"
                onChange={(e) => handleParamChange('treemapDataKey', e.target.value)}
              >
                {headers.map((header) => (
                  <MenuItem key={header} value={header}>
                    {header}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Color Picker for TreeChart */}
            <TextField
                label="Tree Color"
                type="color"
                value={treeColor}
                onChange={handleColorChange}
                sx={{ marginTop: 2, width: '100px' }} // Adjust width as needed
              />
            </>
          )}

          {/* Chart Size Slider */}
          <Box sx={{ marginTop: 3 }}>
            <Typography gutterBottom>Chart Size</Typography>
            <Slider
              value={chartSize}
              onChange={handleSizeChange}
              aria-labelledby="chart-size-slider"
              min={300}
              max={1000}
              step={50}
            />
          </Box>

          <Button variant="contained" onClick={downloadChartAsPNG} sx={{ marginTop: 2 }}>
          Download Chart as PNG
        </Button>
        </Box>
      )}
      </Box>

       {/* Render the selected chart centered */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: 2,
          width: { xs: '100%', sm: '50%' },
          alignItems: 'center',
          marginTop: 4,
          minHeight: '300px',
        }}
      >
        <div ref={chartRef}>
        {renderChart()}
        </div>
      </Box>
      </Box>
  );
};

export default VisualizationTab;