import React, { useState, useEffect } from 'react';
import { Box, Typography, Autocomplete, TextField, Paper, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Tabs, Tab, Grid } from '@mui/material';
import { db } from '../firebase'; // Import Firestore config
import { collection, getDocs } from 'firebase/firestore'; // Firestore methods
import VisualizationTab from './VisualizationTab';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Visualization = () => {
  const [fileOptions, setFileOptions] = useState([]); // Options for the autocomplete box
  const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
  const [spreadsheetMetadata, setSpreadsheetMetadata] = useState(null); // Spreadsheet metadata
  const [tableData, setTableData] = useState([]); // Data to populate the table
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [tabValue, setTabValue] = useState(0); // State for active tab (0 = Table, 1 = Visualization)
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');

  const auth = getAuth();

  // Monitor authentication state and store the user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        setError('You must be logged in to search.');
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, [auth]);

  // Fetch uploaded files from Firestore based on user's email
  useEffect(() => {
    const fetchFiles = async () => {
      if (user) { // Check if the user is logged in
        const userCollectionName = user.email; // Use user's email as the collection name
        const querySnapshot = await getDocs(collection(db, userCollectionName)); // Fetch documents from user's collection
        const fileNames = querySnapshot.docs.map(doc => ({
          label: doc.id, // Use Firestore doc ID as the label
          data: doc.data(), // Store the document's data for later use
        }));
        setFileOptions(fileNames);
      }
    };

    fetchFiles();
  }, [user]);

  // Handle Autocomplete option selection
  const handleFileSelect = (event, value) => {
    if (value) {
      setSelectedFile(value);
      const { headers, rows } = value.data;

      // Set spreadsheet metadata
      setSpreadsheetMetadata({
        numRows: rows.length,
        numCols: headers.length,
        headers,
      });

      // Set table data
      setTableData(rows);
    }
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Data Visualization
      </Typography>

      {/* Grid for Autocomplete and Spreadsheet Metadata Side by Side */}
      <Grid container spacing={2} alignItems="center">
        {/* Autocomplete Search Box */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            options={fileOptions}
            getOptionLabel={(option) => option.label}
            onChange={handleFileSelect}
            renderInput={(params) => (
              <TextField {...params} label="Select a Spreadsheet" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {/* Spreadsheet Metadata Section */}
        {selectedFile && spreadsheetMetadata && (
          <Grid item xs={12} sm={6}>
            <Paper sx={{ padding: 2 }}>
              {/* <Typography variant="h6">Spreadsheet Metadata:</Typography> */}
              <Typography>
                Number of Rows: <strong>{spreadsheetMetadata.numRows}</strong> | 
                Number of Columns: <strong>{spreadsheetMetadata.numCols}</strong>
              </Typography>
              {/* <Typography>
                Headers: {spreadsheetMetadata.headers.join(', ')}
              </Typography> */}
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* Tabs for Table and Visualization */}
      {selectedFile && (
        <Box sx={{ marginTop: 3 }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Table" />
            <Tab label="Visualization" />
          </Tabs>

          {/* Conditional rendering based on the selected tab */}
          {tabValue === 0 && (
            // Table for displaying spreadsheet data
            tableData.length > 0 && (
              <Box sx={{ marginTop: 3 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {spreadsheetMetadata.headers.map((header, index) => (
                        <TableCell key={index} align="center" sx={{ fontWeight: 'bold' }}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Paginate data
                      .map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {spreadsheetMetadata.headers.map((header, colIndex) => (
                            <TableCell key={colIndex} align="center">
                              {row[header]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                {/* Pagination Controls */}
                <TablePagination
                  component="div"
                  count={tableData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            )
          )}

          {tabValue === 1 && (
            <Box sx={{ marginTop: 3 }}>
              <VisualizationTab 
              selectedFile={selectedFile}
              spreadsheetMetadata={spreadsheetMetadata}
              tableData={tableData}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Visualization;
