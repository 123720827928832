import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Snackbar,
  Alert,
  TablePagination,
} from '@mui/material';
import { storage, auth, db } from '../firebase'; // Import Firebase storage & auth
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth'; // To track logged-in user
import Download from '@mui/icons-material/Download'; // Import the Download icon
import Delete from '@mui/icons-material/Delete'; // Import the Delete icon
import { collection, doc, deleteDoc } from 'firebase/firestore'; // Import Firestore document deletion

const AllSheets = () => {
  const [files, setFiles] = useState([]); // Array to hold uploaded files
  const [selectedFile, setSelectedFile] = useState(null); // State for the selected file
  const [message, setMessage] = useState(''); // State for Snackbar message
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar open/close
  const [page, setPage] = useState(0); // Pagination: current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Pagination: rows per page
  const [userEmail, setUserEmail] = useState(''); // Store authenticated user email

  // Track authenticated user on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email); // Set the user email
        fetchFiles(user.email); // Fetch files from user-specific folder
      } else {
        setUserEmail(''); // Reset email if user logs out
        setFiles([]); // Clear files
      }
    });

    return () => unsubscribe(); // Clean up listener
  }, []);

  // Fetch files from user-specific folder in Firebase Storage
  const fetchFiles = async (email) => {
    const folderRef = ref(storage, `${email}/`); // Use user email as folder name
    const response = await listAll(folderRef);

    const urls = await Promise.all(
      response.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return {
          name: item.name,
          url,
          time: item.timeCreated || new Date().toISOString(), // Use upload time if available
        };
      })
    );

    setFiles(urls); // Update files state with fetched URLs
  };

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage('Please select a file to upload');
      setOpenSnackbar(true);
      return;
    }

    try {
      const fileRef = ref(storage, `${userEmail}/${selectedFile.name}`); // Store file in user-specific folder
      await uploadBytes(fileRef, selectedFile);
      setMessage('File uploaded successfully!');
      fetchFiles(userEmail); // Refresh the file list
    } catch (error) {
      setMessage('File upload failed!');
    } finally {
      setOpenSnackbar(true);
      setSelectedFile(null); // Reset file selection
    }
  };

  // Handle file deletion
  const handleDelete = async (fileName) => {
    const fileRef = ref(storage, `${userEmail}/${fileName}`); // Reference to the file to be deleted
    const docRef = doc(collection(db, userEmail), fileName);
    try {
      await deleteObject(fileRef); // Delete the file from Firebase Storage
      await deleteDoc(docRef); // Delete from Firestore
      setMessage('File deleted successfully!');
      fetchFiles(userEmail); // Refresh the file list after deletion
    } catch (error) {
      setMessage('File deletion failed!');
    } finally {
      setOpenSnackbar(true);
    }
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Handle Pagination: Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle Pagination: Change rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Upload Spreadsheet
      </Typography>
      <input
        type="file"
        accept=".xlsx,.xls,.csv"
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        sx={{ marginLeft: 2, backgroundColor: '#021526' }}
      >
        Upload
      </Button>

      <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
        Uploaded Sheets
      </Typography>

      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Upload Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file) => (
                <TableRow key={file.name}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{new Date(file.time).toLocaleString()}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary" // Change to primary for blue color
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Download />
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleDelete(file.name)} // Add onClick for delete
                      sx={{ marginLeft: 1 }} // Add some spacing
                    >
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={files.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]} // Adjust options as needed
      />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AllSheets;
