import React, { useState, useEffect } from 'react';
import { Container, Typography, Avatar, Box, Button, Toolbar, Divider } from '@mui/material';
import { AppBar } from '@mui/material'; // Import AppBar components
import { signOut } from 'firebase/auth';
import { auth } from '../firebase'; // Import Firebase authentication instance
import { Link, useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Fetch the current user from Firebase Authentication
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
    } else {
      navigate('/login'); // Redirect if not logged in
    }
  }, [navigate]);

  // Handle Logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert('Logged out successfully!');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Error logging out: ' + error.message);
    }
  };

//   console.log(user);
// console.log('Photo URL:', user.photoURL);


  if (!user) return <Typography>Loading...</Typography>; // Show loading state

  return (
    <>
    {/* AppBar with Home and Dashboard Links */}
    <AppBar sx={{ backgroundColor: '#021526' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              ManageME
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" component={Link} to="/dashboard">
            Dashboard
          </Button>
        </Toolbar>
    </AppBar>

    {/* User Profile Content Centered */}
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 10 }}>
      <Avatar
        alt={user.displayName}
        src={user.photoURL}
        sx={{ width: 120, height: 120, margin: 'auto' }}
      />
      <Typography variant="h4">{user.displayName || 'No Name'}</Typography>
      <Typography variant="body1" color="textSecondary">
          {user.email}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="body2" sx={{ mt: 1 }}>
          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          UID: {user.uid}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Last Login: {user.metadata?.lastSignInTime || 'N/A'}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, mb: 3 }}>
          Account Created: {user.metadata?.creationTime || 'N/A'}
        </Typography>
      <Button variant="contained" color="primary" sx={{ mt: 3, backgroundColor: '#021526' }} onClick={handleLogout}>
        Logout
      </Button>
    </Container>
    </>
  );
};

export default UserProfile;
