import React, { useState, useEffect } from 'react';
import { CssBaseline, Box, Toolbar } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import React Router components
import Appbar from './components/Appbar';
import Sidebar from './components/Sidebar';
import AllSheets from './components/AllSheets';
import GlobalSearch from './components/GlobalSearch';
import Visualization from './components/Visualization';
import Home from './Home'; // Import Home page\
import Login from './components/Login';
import SignUp from './components/SignUp';
import ProtectedRoute from './ProtectedRoute';
import UserProfile from './components/UserProfile';

function App() {
  const [currentComponent, setCurrentComponent] = useState('All Sheets');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case 'All Sheets':
        return <AllSheets />;
      case 'Global Search':
        return <GlobalSearch />;
      case 'Visualization':
        return <Visualization />;
      default:
        return <AllSheets />;
    }
  };

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} /> {/* Route for Home page */}
          <Route path="/dashboard" element={
            <>
              <Appbar toggleSidebar={toggleSidebar} />
              <Sidebar 
                open={isSidebarOpen} 
                toggleSidebar={toggleSidebar} 
                setCurrentComponent={setCurrentComponent} 
              />
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar /> {/* Space for Appbar */}
                <ProtectedRoute>
                {renderComponent()}
                </ProtectedRoute>
              </Box>
            </>
          }/>
          <Route path="/login" element={<Login />} /> {/* Login Route */}
          <Route path="/signup" element={<SignUp />} /> {/* SignUp Route */}
          <Route path="/profile" element={<UserProfile />} /> {/* Profile Route */}
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
