import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { AccountBox } from '@mui/icons-material';

const Appbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/profile');
  }

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#021526' }} >
      <Toolbar>
        <IconButton 
          color="inherit" 
          aria-label="open drawer" 
          edge="start" 
          onClick={toggleSidebar} 
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" noWrap component="div">
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            ManageME
          </Link>
        </Typography>

        <div style={{ flexGrow: 1 }}></div>
        {/* User Profile Icon */}
        <IconButton
          color="inherit"
          aria-label="profile"
          onClick={handleProfileClick} // Handle profile icon click
        >
          <AccountBox />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
