import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import AppbarHome from './AppbarHome';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '../assets/google_logo1.png'

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  // Handle Email and Password Sign-Up
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User registered:', userCredential.user);
      alert('User registered successfully!');
      navigate('/login');
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Error signing up: ' + error.message);
    }
  };

  // Handle Google Sign-Up
  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('Google user registered:', result.user);
      alert('User registered successfully with Google!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error with Google sign-up:', error);
      alert('Error with Google sign-up: ' + error.message);
    }
  };

  return (
    <div>
      <AppbarHome />
      <Container maxWidth="sm" sx={{ mt: 10 }}>
        <Box
          component="form"
          onSubmit={handleSignUp}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Sign Up
          </Typography>
          <TextField
            label="Email"
            type="email"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ backgroundColor: '#021526' }}>
            Sign Up
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleGoogleSignUp}
            sx={{ mt: 2 }}
          >
            <img src={GoogleIcon} alt="Google logo" style={{ width: 20, height: 20, marginRight: 8 }} />
            Sign Up with Google
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default SignUp;
