import React from 'react';
import { CssBaseline, Box, Toolbar, Container, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import AppbarHome from './components/AppbarHome';
import Screenshot1 from './assets/all_sheets.png';
import Screenshot2 from './assets/globasearch.png';
import Screenshot3 from './assets/visualization.png';
import DataVisualizationImg from './assets/DataVisualizationImg.jpeg';
import GlobalSearchImg from './assets/Designer.jpeg';
import CollaborativeToolsImg from './assets/CollaborativeToolsImg.jpeg';
import SecurityImg from './assets/SecurityImg.jpeg';
import Hero from './assets/hero1.jpeg';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <CssBaseline />
      <AppbarHome />
      <Toolbar />

      {/* Hero Section */}
      <Box 
        sx={{
          width: '100%',
          bgcolor: '#021526',
          py: 8,
          color: 'white',
          textAlign: 'center',
          backgroundImage: `url(${Hero})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container maxWidth="md" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', p: 3, borderRadius: 2 }}>
          <Typography variant="h2" gutterBottom>
            Welcome to ManageME
          </Typography>
          <Typography variant="h6" gutterBottom>
            The no-code data analysis platform to visualize, manage, and understand your data effortlessly.
          </Typography>
          <Button variant="contained" color="primary" size="large" sx={{ mt: 3 }} component={Link} to="/signup">
            Get Started
          </Button>
        </Container>
      </Box>

      {/* Feature Highlights Section */}
      <Box sx={{ py: 8, textAlign: 'center', width: '100%' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" gutterBottom>
      Key Features
    </Typography>
    <Grid container spacing={4}>
      {[
        { title: 'Data Visualization', desc: 'Create insightful charts and dashboards easily.', img: DataVisualizationImg },
        { title: 'Global Search', desc: 'Quickly search through your entire data set.', img: GlobalSearchImg },
        { title: 'Collaborative Tools', desc: 'Collaborate with your team in real-time.', img: CollaborativeToolsImg },
        { title: 'Security', desc: 'Your data is safe with advanced encryption.', img: SecurityImg }
      ].map((feature, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card sx={{ minHeight: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardMedia
              component="img"
              image={feature.img}
              alt={feature.title}
              sx={{
                height: '100px',
                width: 'auto',
                marginTop: 2,
                borderRadius: '30%', // Optional: Make the image circular if desired
                filter: 'blur(0px)', // Blur the entire image
                boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.1)', // Add a soft glow
              }}
            />
            <CardContent>
              <Typography variant="h5" component="div">
                {feature.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {feature.desc}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>


      {/* Product Demo Section */}
      <Box sx={{ py: 8, bgcolor: '#f4f4f9', textAlign: 'center', width: '100%' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            See ManageME in Action
          </Typography>
          <Grid container spacing={4}>
            {[Screenshot1, Screenshot2, Screenshot3].map((img, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CardMedia
                  component="img"
                  height="250"
                  image={img}
                  alt={`Screenshot ${index + 1}`}
                  sx={{ borderRadius: 2 }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

       {/* Testimonials Section */}
       <Box sx={{ py: 8, textAlign: 'center' }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            What Our Users Say
          </Typography>
          <Grid container spacing={4}>
            {[
              { name: 'Jane Doe', feedback: 'ManageME transformed our data handling process.' },
              { name: 'John Smith', feedback: 'Quick, easy, and efficient data analysis.' },
              { name: 'Alice Lee', feedback: 'The perfect tool for collaborative data work!' }
            ].map((testimonial, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card sx={{ minHeight: 180 }}>
                  <CardContent>
                    <Typography variant="body1" component="p" gutterBottom>
                      "{testimonial.feedback}"
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      - {testimonial.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Pricing Plans Section
      <Box sx={{ py: 8, bgcolor: '#f4f4f9', textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Choose Your Plan
          </Typography>
          <Grid container spacing={4}>
            {[
              { title: 'Basic', price: '$10/month', features: ['Data Visualization', 'Global Search'] },
              { title: 'Pro', price: '$30/month', features: ['All Basic Features', 'Collaborative Tools', 'Advanced Analytics'] },
              { title: 'Enterprise', price: 'Contact Us', features: ['Custom Integrations', 'Priority Support', 'Unlimited Users'] }
            ].map((plan, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ minHeight: 300 }}>
                  <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                      {plan.title}
                    </Typography>
                    <Typography variant="h6" color="primary" gutterBottom>
                      {plan.price}
                    </Typography>
                    <ul>
                      {plan.features.map((feature, i) => (
                        <li key={i}>
                          <Typography variant="body2">{feature}</Typography>
                        </li>
                      ))}
                    </ul>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                      {plan.title === 'Enterprise' ? 'Contact Us' : 'Choose Plan'}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}

       {/* Call to Action Section */}
       <Box sx={{ py: 8, textAlign: 'center' }}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            Ready to Transform Your Data?
          </Typography>
          <Button variant="contained" color="primary" size="large" sx={{ mt: 3 }} component={Link} to="/signup" >
            Sign Up Now
          </Button>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ py: 4, bgcolor: '#021526', color: 'white', textAlign: 'center', width: '100%' }}>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} ManageME. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;