import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Button, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase auth import

const GlobalSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null); // Store the logged-in user

  const auth = getAuth();
  const functions = getFunctions();

  // Monitor authentication state and store the user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        setError('You must be logged in to search.');
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, [auth]);

  const handleSearch = async () => {
    setError(''); // Clear any previous error

    if (!user) {
      setError('You must be logged in to perform a search.');
      return;
    }

    if (!searchQuery.trim()) {
      setError('Please enter a valid search query.');
      return;
    }

    const searchSheets = httpsCallable(functions, 'searchSheets');

    try {
      const response = await searchSheets({ query: searchQuery });
      setResults(response.data.results);
    } catch (err) {
      setError('Error searching the database. Please try again.');
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Global Search
      </Typography>
      <TextField
        label="Search across your sheets"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button variant="contained" color="primary" sx={{ mt: 2, backgroundColor: '#021526' }} onClick={handleSearch}>
        Search
      </Button>

      {error && <Typography color="error">{error}</Typography>}
      
      {/* Display message if results are empty */}
      {results.length === 0 && searchQuery && !error && (
        <Typography color="textSecondary" sx={{ mt: 2 }}>
          No results found for "{searchQuery}". Please try a different search term.
        </Typography>
      )}
      
      {results.length > 0 && (
        <div>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Search Results:
          </Typography>
          {results.map((result, index) => (
            <TableContainer component={Paper} key={index} sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                {result.fileName}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {result.headers.map((header, idx) => (
                      <TableCell key={idx}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {result.headers.map((header, colIndex) => (
                        <TableCell key={colIndex}>{row[header]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
